<template>
  <div class="cardAccessDetails">
    <div class="topImg"><img alt="" src="../../assets/Rectangle 50.png" /></div>
    <div class="leftImg"><img alt="" src="../../assets/Ellipse 18.png" /></div>
    <div class="content" :class="width == 1 ? 'contect-url' : ''">
      <div class="card">
        <div class="card-right">
          <div class="card-right-title">Issue Cards</div>
          <div class="card-right-text">
            Surprise your customers with the services of OpenCrypto and delight
            them with the prices. In addition, we offer solutions with great
            flexibility, diversity and variety. Your insights are always valued
            and considered to create the best, most suitable solutions for you.
          </div>
        </div>
        <div class="catd-left">
          <img src="../../assets/card/card3.png" alt="" />
        </div>
      </div>
      <div class="table-overflow">
        <table class="table-box">
          <tr>
            <th></th>
            <th>Generic Card</th>
            <th>Full Brand Card</th>
            <th>Virtual Card</th>
          </tr>
          <tr v-for="item in tableData" :key="item.title">
            <td>{{ item.title }}</td>
            <td v-for="items in item.nameList" :key="items">
              <div>
                <div class="box" v-for="(itemName, index) in items.name" :key="index">
                  <span v-if="items.type == 1">
                    {{ itemName }}
                  </span>
                  <div v-else class="box-img">
                    <img class="img-icon1" :src="itemName" alt="" />
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="center">
      <div class="center-title">
        <div>
          Three typical methodologies for
          <span style="color: #4047d6">deploy</span>, for your consideration.
        </div>
      </div>
      <div class="center-box">
        <div class="box-content">
          <img class="box-img" src="../../assets/card/card-icon4.png" alt="" />
          <div class="box-text">Client One: Startup</div>
          <div class="box-describe" v-for="(item, index) in list" :key="index">
            <img src="../../assets/card/card-icon3.png" alt="" />
            <span>{{ item }}</span>
          </div>
          <div class="suggest">DEPLOY SUGGESTION:</div>
          <table class="box-content-table">
            <tr>
              <td>Virtual card</td>
              <td>EUR,USD</td>
              <td class="box-content-table-td">
                <img src="../../assets/card/card-icon.png" alt="" />
              </td>
            </tr>
          </table>
        </div>
        <div class="box-content">
          <img class="box-img" src="../../assets/card/card-icon4.png" alt="" />
          <div class="box-text">Client two: Payment Provider</div>
          <div class="box-describe" v-for="(item, index) in list1" :key="index">
            <img src="../../assets/card/card-icon3.png" alt="" />
            <span>{{ item }}</span>
          </div>
          <div class="suggest">DEPLOY SUGGESTION:</div>
          <table class="box-content-table">
            <tr>
              <td>Virtual card</td>
              <td>EUR,USD</td>
              <td>
                <img src="../../assets/card/card-icon.png" alt="" />
              </td>
            </tr>
            <tr>
              <td>Generic Card</td>
              <td>EU,USD</td>
              <td>
                <img src="../../assets/card/card-icon.png" alt="" />
              </td>
            </tr>
            <tr>
              <td>Generic Card</td>
              <td>USD</td>
              <td>
                <img src="../../assets/card/card-icon1.png" alt="" />
              </td>
            </tr>
          </table>
        </div>
        <div class="box-content">
          <img class="box-img" src="../../assets/card/card-icon4.png" alt="" />
          <div class="box-text">Client three: Influential project</div>
          <div class="box-describe" v-for="(item, index) in list2" :key="index">
            <img src="../../assets/card/card-icon3.png" alt="" />
            <span>{{ item }}</span>
          </div>
          <div class="suggest">DEPLOY SUGGESTION:</div>
          <table class="box-content-table">
            <tr>
              <td>Virtual card</td>
              <td>EUR,USD</td>
              <td>
                <img src="../../assets/card/card-icon.png" alt="" />
              </td>
            </tr>
            <tr>
              <td>Generic Card</td>
              <td>EU, USD</td>
              <td>
                <div class="box-content-icon">
                  <img style="margin-right: 15px;" src="../../assets/card/card-icon.png" alt="" />
                  <img src="../../assets/card/card-icon1.png" alt="" />
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="bottom">
        <div class="bottom-title">A variety of card schemes</div>
        <div class="bottom-h2">
          Customized Metal Card
          <span style="color: #4047d6">customization</span> service.
        </div>
        <div class="bottom-center">
          Fully designed on-demand, combined with our highest spending card
          within the industry. The service is available in Visa USD, UPI USD and
          Master EU account.
        </div>
        <img class="bottom-img" src="../../assets/card/card4.png" alt="" />
        <br />
        <div class="bottom-card-color">
          <div class="card-top">CHOOSE YOUR CARD DESIGN</div>
          <div class="flex">
            <div class="card-bottom">
              <div style="background-color: rgb(160, 160, 160)" class="card-color"></div>
              <div class="color-text">SILVER</div>
            </div>
            <div class="card-bottom">
              <div style="background-color: rgb(117, 117, 117)" class="card-color"></div>
              <div class="color-text">BRUSHED</div>
            </div>
            <div class="card-bottom">
              <div style="background-color: rgb(0, 0, 0)" class="card-color"></div>
              <div class="color-text">SILVER</div>
            </div>
            <div class="card-bottom">
              <div style="background-color: rgb(210, 137, 75)" class="card-color"></div>
              <div class="color-text">SILVER</div>
            </div>
            <div class="card-bottom">
              <div style="background-color: rgb(218, 218, 218)" class="card-color"></div>
              <div class="color-text">SILVER</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cardAccessDetails",
  data() {
    return {
      width: 1, // 1 web 2 H5
      srcimg: "../../assets/card/card-icon1.png",
      tableData: [
        {
          title: "Card type",
          nameList: [
            {
              type: 1,
              name: ["Classic/Premium"],
            },
            {
              type: 1,
              name: ["Classic"],
            },
            {
              type: 1,
              name: ["Virtual"],
            },
          ],
        },
        {
          title: "Card scheme support",
          nameList: [
            {
              type: 2,
              name: [
                require("../../assets/card/card-icon1.png"),
              ],
            },
            {
              type: 2,
              name: [
                require("../../assets/card/card-icon1.png"),
              ],
            },
            {
              type: 2,
              name: [
                require("../../assets/card/card-icon1.png"),
              ],
            },
          ],
        },
        {
          title: "Region over",
          nameList: [
            {
              type: 1,
              name: ["Global"],
            },
            {
              type: 1,
              name: ["EU only"],
            },
            {
              type: 1,
              name: ["Global"],
            },
          ],
        },
        {
          title: "Set up",
          nameList: [
            {
              type: 1,
              name: ["Within 10 days"],
            },
            {
              type: 1,
              name: ["Within 40 days"],
            },
            {
              type: 1,
              name: ["Within 5 days"],
            },
          ],
        },
        {
          title: "API support",
          nameList: [
            {
              type: 1,
              name: ["N"],
            },
            {
              type: 1,
              name: ["Y"],
            },
            {
              type: 1,
              name: ["Y"],
            },
          ],
        },
        {
          title: "PCI support",
          nameList: [
            {
              type: 1,
              name: ["N"],
            },
            {
              type: 1,
              name: ["Y"],
            },
            {
              type: 1,
              name: ["Y"],
            },
          ],
        },
        {
          title: "CARD MANAGEMENT PORTAL",
          nameList: [
            {
              type: 1,
              name: ["N"],
            },
            {
              type: 1,
              name: ["N"],
            },
            {
              type: 1,
              name: ["N"],
            },
          ],
        },
        {
          title: "Classic/Premium",
          nameList: [
            {
              type: 1,
              name: ["N"],
            },
            {
              type: 1,
              name: ["N"],
            },
            {
              type: 1,
              name: ["N"],
            },
          ],
        },
      ],
      list: [
        "Live within 30 days",
        "API Fully Support",
        "Virtual card (Unbranded) to minimum order barrier",
        "No Physical card to avoid high cost.",
        "Metal card (Fully customize) for VIP scenarios",
        "Low setup fee",
      ],
      list1: [
        "Live within 60 days",
        "API Fully Support",
        "Virtual card (Unbranded) to minimum order barrier",
        "Physical card (Generic) for real world spending scenarios",
        "Metal card (Fully custom) for VIP scenarios",
        "Medium setup fee",
      ],
      list2: [
        "Live within 90 days",
        "API Fully Support",
        "Virtual card (Fully branded) to promote branding",
        "Physical card (Fully white label) to achieve large scale price",
        "Metal card (Fully custom) for VIP scenarios",
        "High setup fee",
      ],
    };
  },
  created() { },
  mounted() {
    //获取页面宽度
    this.checkMediaQuery();
    window.addEventListener("resize", this.checkMediaQuery);
  },
  methods: {
    //监听页面宽度变化
    checkMediaQuery() {
      const mediaQueryList = window.matchMedia("(max-width: 768px)");
      if (mediaQueryList.matches) {
        if (this.width == 1) this.width = 2;
      } else {
        if (this.width == 2) this.width = 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../css/public.scss";

.cardAccessDetails {
  margin-bottom: 260px;
}

.center {
  padding-top: 160px;

  .center-title {
    width: 80%;
    margin: auto;
    text-align: left;

    div {
      max-width: 700px;
      line-height: 60px;
      font-size: 40px;
      color: #1e253a;
    }
  }

  .center-box {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: auto;
    margin-top: 80px;
    text-align: left;

    .box-content {
      display: flex;
      flex-direction: column;

      tr {
        color: rgba(30, 37, 58, 0.6);
      }

      .box-img {
        width: 60px;
        height: 60px;
      }

      .box-describe {
        display: flex;
        align-items: center;
        margin-top: 10px;

        img {
          height: 12px;
          width: 16px;
        }

        span {
          margin-left: 20px;
          font-size: 16px;
          color: rgba(30, 37, 58, 0.6);
          display: inline-block;
        }
      }

      .box-text {
        margin-top: 40px;
        margin-bottom: 30px;
        font-size: 22px;
        line-height: 28px;
      }

      .suggest {
        margin-top: 40px;
        margin-bottom: 20px;
        font-size: 18px;
        line-height: 28px;
      }

      .box-content-table {
        img {
          width: 32px;
          height: 20px;
        }

        tr {
          height: 35px;
          font-size: 18px;
        }

        .box-content-icon {
          display: flex;
        }
      }
    }
  }
}

.bottom {
  padding-top: 160px;

  text-align: center;

  .bottom-title {
    font-size: 16px;
    line-height: 28px;
    color: rgba(30, 37, 58, 0.6);
  }

  .bottom-h2 {
    font-size: 40px;
    line-height: 48px;
    color: #1e253a;
    margin-top: 10px;
  }

  .bottom-center {
    max-width: 900px;
    margin: 30px auto 0;
    font-size: 24px;
    line-height: 30px;
    color: rgba(30, 37, 58, 0.6);
  }

  .bottom-img {
    display: inline-block;
    margin-top: 160px;
    width: 100%;
    max-width: 500px;
  }

  .flex {
    display: flex;
  }

  .bottom-card-color {
    margin-top: 60px;
    display: inline-block;

    .card-top {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 28px;
    }

    .card-bottom {
      margin-right: 32px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: center;

      &> :last-child {
        margin-right: 0 !important;
      }

      .card-color {
        width: 50px;
        height: 10px;
        border-radius: 2px;
        margin: auto;
      }

      .color-text {
        margin-top: 10px;
        color: rgba(30, 37, 58, 0.3);
      }
    }
  }
}

.content {
  background-color: #f5f9fb;
}

.contect-url {
  background-image: url(https://railone.io/img/security-bg.489487d1.svg);
  background-repeat: no-repeat;
  background-position: 100% 280px;
}

.leftImg {
  width: 400px;
  height: 410px;
  position: absolute;
  top: 0px;
}

.topImg {
  width: 100%;
  height: 720px;
  position: absolute;
  top: 0;
  left: 0;
}

.card {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 186px;

  .catd-left {
    width: 640px;

    img {
      width: 100%;
      max-width: 640px;
    }
  }

  .card-right {
    text-align: left;

    .card-right-title {
      font-size: 62px;
      font-weight: 500;
      line-height: 87px;
    }

    .card-right-text {
      max-width: 650px;
      margin-top: 30px;
      margin-bottom: 60px;
      font-size: 16px;
      line-height: 28px;
      color: rgba(30, 37, 58, 0.6);
    }
  }
}

.table-box {
  width: 80%;
  margin: auto;
  border-collapse: collapse;
  font-size: 16px;
  font-weight: 400;
  margin-top: 160px;

  tr {
    text-align: left;

    &> :nth-child(1) {
      color: rgba(30, 37, 58, 0.3);
      border-left: none !important;
    }

    th {
      height: 70px;
      border-bottom: 2px solid #f2f2f2;
      border-left: 2px solid #f2f2f2;
      padding-left: 10px;
    }

    td {
      height: 70px;
      border-bottom: 2px solid #f2f2f2;
      border-left: 2px solid #f2f2f2;
      padding-left: 10px;
    }
  }

  .box {
    width: 50%;
    display: inline-block;
    text-align: left;
  }

  .box-img {
    display: flex;

    img {
      display: inline-block;
      margin-right: 30px;
    }
  }
}

.img-icon {
  width: 32px;
  height: 20px;
}

.img-icon1 {
  width: 50px;
  height: 16px;
}

@media screen and (max-device-width: 768px) {
  .content {
    padding: 0 100px;
    padding-top: 220px;

    .card {
      display: flex;
      flex-direction: column;

      .card-right {
        text-align: center;

        .card-right-title {
          font-size: 120px;
          font-weight: 500;
          line-height: 175px;
        }

        .card-right-text {
          max-width: 1300px;
          margin-top: 60px;
          margin-bottom: 120px;
          font-size: 50px;
          line-height: 100px;
          color: rgba(30, 37, 58, 0.6);
          word-wrap: break-word;
          word-break: break-all;
        }
      }

      .catd-left {
        width: 100%;

        img {
          width: 100%;
          max-width: 100%;
        }
      }
    }

    .table-overflow {
      width: 100%;
      overflow-x: auto;
      /* 横向滚动条 */
      white-space: nowrap;
      /* 内容不换行 */

      .table-box {
        margin-top: 200px;
        width: 100%;
        border-collapse: collapse;
        font-size: 50px;

        tr {
          text-align: left;

          &> :nth-child(1) {
            color: rgba(30, 37, 58, 0.3);
          }

          th {
            height: 150px;
            border-bottom: 2px solid #f2f2f2;
            border-left: 2px solid #f2f2f2;
            padding-left: 20px;
          }

          td {
            height: 150px;
            border-bottom: 2px solid #f2f2f2;
            border-left: 2px solid #f2f2f2;
            padding-left: 20px;
          }
        }
      }

      .box {
        width: 50%;
        display: inline-block;
        text-align: left;

        .img-icon1 {
          width: 100px;
          height: 32px;
        }
      }

      .box-img {
        display: flex;

        img {
          display: inline-block;
          margin-right: 30px;
        }
      }
    }
  }

  .center {
    padding: 0 100px;
    padding-top: 320px;

    .center-title {
      width: 100%;
      margin: auto;
      text-align: center;
      display: flex;
      justify-content: center;

      div {
        max-width: 1400px;
        line-height: 120px;
        font-size: 80px;
        color: #1e253a;
      }
    }

    .center-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 160px;
      text-align: left;

      .box-content {
        display: flex;
        flex-direction: column;
        margin-bottom: 200px;

        tr {
          color: rgba(30, 37, 58, 0.6);
        }

        .box-img {
          width: 240px;
          height: 240px;
        }

        .box-describe {
          display: flex;
          align-items: center;
          margin-top: 40px;

          img {
            height: 40px;
            width: 50px;
          }

          span {
            display: inline-block;
            margin-left: 20px;
            font-size: 50px;
            color: rgba(30, 37, 58, 0.6);
          }
        }

        .box-text {
          margin-top: 80px;
          margin-bottom: 60px;
          font-size: 60px;
          line-height: 50px;
        }

        .suggest {
          margin-top: 80px;
          margin-bottom: 40px;
          font-size: 50px;
          line-height: 50px;
        }

        .box-content-table {
          img {
            width: 90px !important;
            height: 55px !important;
          }

          tr {
            height: 100px;
            font-size: 50px;
          }

          .box-content-icon {
            display: flex;

            img {
              width: 64px;
              height: 40px;
            }
          }
        }
      }
    }
  }

  .bottom {
    padding-top: 160px;
    text-align: center;
    padding: 0 100px;

    .bottom-title {
      font-size: 30px;
      line-height: 50px;
      color: rgba(30, 37, 58, 0.6);
    }

    .bottom-h2 {
      font-size: 80px;
      line-height: 120px;
      color: #1e253a;
      margin-top: 80px;
    }

    .bottom-center {
      max-width: 1800px;
      margin: 60px auto 0;
      font-size: 50px;
      line-height: 90px;
      color: rgba(30, 37, 58, 0.6);
    }

    .bottom-img {
      display: inline-block;
      margin-top: 200px;
      width: 100%;
      max-width: 100%;
    }

    .flex {
      display: flex;
    }

    .bottom-card-color {
      margin-top: 120px;
      display: inline-block;

      .card-top {
        margin-bottom: 40px;
        font-size: 40px;
        line-height: 60px;
      }

      .card-bottom {
        margin-right: 60px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;

        &> :last-child {
          margin-right: 0 !important;
        }

        .card-color {
          width: 120px;
          height: 25px;
          border-radius: 4px;
          margin: auto;
        }

        .color-text {
          margin-top: 20px;
          color: rgba(30, 37, 58, 0.3);
        }
      }
    }
  }
}
</style>